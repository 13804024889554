// navData.jsDd
const navData = [
  { label: 'Home', link: '/' },
  { label: 'About', link: '/about' },
  {
    label: 'Services',
    link: '#',
    submenu: [
      { label: 'Private Investigation', link: '/services/private-investigation' },
      { label: 'Domestic Investigation', link: '/services/domestic-investigation' },
      { label: 'Insurance Investigation', link: '/services/insurance-investigation' },
      { label: 'Bug Sweeps', link: '/services/bug-sweeps' },
      { label: 'Corporate Investigation', link: '/services/corporate-investigation' },
      { label: 'Infidelity Investigation', link: '/services/infidelity-investigation' },
      { label: 'Locate People', link: '/services/locate-people' },

    ],
  },
  { label: 'Testimonial', link: '/testimonial' },
  { label: 'FAQ', link: '/faq' },
  { label: 'News', link: '/news' },
  { label: 'Contact', link: '/contact' },
  { label: 'Start', link: '/freequote' },
];
export default navData;

