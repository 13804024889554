import { FaSearch, FaUser, FaShieldAlt, FaVideo, FaCog, FaUserCircle } from 'react-icons/fa';
import { IoMdKey } from 'react-icons/io';
import { FaLock } from 'react-icons/fa';


const cardData = [

  {
    symbol: <FaSearch />,
    title: "Private Investigation",
    paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima",
    buttonText: "Read More",
    link: '/services/private-investigation'
  },
  {
    symbol: <FaUser />,
    title: "Domestic Investigation",
    paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima",
    buttonText: "Read More",
    link :"/services/domestic-investigation"

  },
  {
    symbol: <IoMdKey />,
    title: "Insurance Investigation",
    paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima",
    buttonText: "Read More",
    link :"/services/insurance-investigation"

  },
  {
    symbol: <FaVideo />,
    title: "Bugs Sweeps Invest...",
    paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima",
    buttonText: "Read More",
     link :"/services/bug-sweeps"

  },
  {
    symbol: <FaCog />,
    title: "Corporate Investigation",
    paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima",
    buttonText: "Read More",
    link :"/services/corporate-investigation"

  },
  {
    symbol:   <FaLock/>,
    title: "Infidelity Investigation",
    paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima",
    buttonText: "Read More",
    link :"/services/infidelity-investigation"

  },
  {
    symbol: <FaUserCircle />,
    title: "Locate People Invest...",
    paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, minima",
    buttonText: "Read More",
    link :"/services/locate-people"

  }
];

export default cardData;
