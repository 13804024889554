import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './PersonalInformation.css'
import data from '../data/data'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Checkbox from './CheckBox'
import Navbar from '../pages/navbar/Navbar'

const PersonalInformation = () => {
  const [windowIndex, setWindowIndex] = useState(0)

  // Form fields state
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [address, setAddress] = useState('')

  // Other form fields
  const [investigationType, setInvestigationType] = useState('')
  const [description, setDescription] = useState('')
  const [uploadDocument, setUploadDocument] = useState('')
  const [uploadPhoto, setUploadPhoto] = useState(null)
  const [timelineStart, setTimelineStart] = useState(null)
  const [timelineEnd, setTimelineEnd] = useState(null)
  const [communicationMethod, setCommunicationMethod] = useState('')
  const [comments, setComments] = useState('')
  const [packageType, setPackageType] = useState('')
  const [height, setHeight] = useState('')
  const [heightUnit, setHeightUnit] = useState('')
  const [eyeColor, setEyeColor] = useState('')
  const [weight, setWeight] = useState('')

  const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(true)

  const handlePrivacyPolicyChange = (e) => {
    setAgreedToPrivacyPolicy(e.target.checked)
  }


  const handleFirstWindowNext = (e) => {
    e.preventDefault()
    setWindowIndex(1)
  }

  const handleSecondWindowNext = (e) => {
    e.preventDefault()
    setWindowIndex(2)
  }

  const handleThirdWindowNext = (e) => {
    e.preventDefault()
    setWindowIndex(3)
  }

  const handleBack = () => {
    if (windowIndex === 1) {
      setWindowIndex(0)
    } else if (windowIndex === 2) {
      setWindowIndex(1)
    } else if (windowIndex === 3) {
      setWindowIndex(2)
    }
  }

  const handleFormCancel = () => {
    // Reset form fields
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhoneNumber('')
    setAddress('')
    setInvestigationType('')
    setDescription('')
    setUploadDocument('')
    setTimelineStart(null)
    setTimelineEnd(null)
    setCommunicationMethod('')
    setComments('')
    setPackageType('')
    setHeight('')
    setHeightUnit('')
    setEyeColor('')
    setWeight('')

    // Reset window index
    setWindowIndex(0)
  }

  const handleFormSubmit = (e) => {

    e.preventDefault()
    const formData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      investigationType,
      description,
      uploadDocument,
      timelineStart,
      timelineEnd,
      communicationMethod,
      comments,
      packageType,
      height,
      heightUnit,
      eyeColor,
      weight,
    }

    axios
      .post('https://chat-investigation1.onrender.com/api/investigations', formData)
      // .post('http://localhost:5000/api/investigations',formData)
      .then((response) => {
        // Handle success
        console.log('Form submitted successfully:', response.data)

        // Reset form fields
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhoneNumber('')
        setAddress('')
        setInvestigationType('')
        setDescription('')
        setUploadDocument(null)
        setUploadPhoto(null)
        setTimelineStart(null)
        setTimelineEnd(null)
        setCommunicationMethod('')
        setComments('')
        setPackageType('')
        setHeight('')
        setHeightUnit('')
        setEyeColor('')
        setWeight('')

        // Reset window index
        setWindowIndex(0)
      })
      .catch((error) => {
        // Handle error
    console.error('Form submission error:', error)
    console.log('Error response:', error.response)
    console.log('Error status:', error.response.status)
    console.log('Error data:', error.response.data)
      })
  }

  return (
    <>
          <Navbar/>
  
    <div className="container">
  
      <h2 className="personalInformation_titles">Personal Information</h2>

      {windowIndex === 0 && (
        <form onSubmit={handleFirstWindowNext}>
          {/* First Window Fields */}
          <div className="container__firstName-lastName">
            <div className="name-container">
              <label htmlFor="firstName"> FirstName</label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                required
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className="name-container">
              <label htmlFor="lastName">LastName</label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                required
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            required
            onChange={(e) => setPhoneNumber(e.target.value)}
          />

          <label htmlFor="address">Address</label>
          <input
            type="text"
            id="address"
            value={address}
            required
            onChange={(e) => setAddress(e.target.value)}
          />

          <button type="submit">Next</button>
        </form>
      )}

      {windowIndex === 1 && (
        <form onSubmit={handleSecondWindowNext}>
          {/* Second Window Fields */}
          <label htmlFor="investigationType">Investigation Type</label>
          <select
            id="investigationType"
            value={investigationType}
            required
            onChange={(e) => setInvestigationType(e.target.value)}
          >
            <option value="">Select Investigation Type</option>
            {data.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
            cols={30}
            rows={5}
          ></textarea>
          <label htmlFor="uploadDocument">Upload Document</label>
          <input
            type="file"
            id="uploadDocument"
            onChange={(e) => setUploadDocument(e.target.files[0])}
          />

          <div className="container__back-nextButton">
            <div className="back-btn">
              {windowIndex > 0 && (
                <button
                  type="button"
                  onClick={handleBack}
                  className="back-button"
                >
                 Back
                </button>
              )}
            </div>
            <div className="next-btn">
              <button type="submit">
                Next 
              </button>
            </div>
          </div>
        </form>
      )}

      {windowIndex === 2 && (
        <form onSubmit={handleThirdWindowNext}>
          {/* Third Window Fields */}
          <label htmlFor="timelineStart">Timeline Start</label>
          <DatePicker
            selected={timelineStart}
            required
            onChange={(date) => setTimelineStart(date)}
          />
          <label htmlFor="timelineEnd">Timeline End</label>
          <DatePicker
            selected={timelineEnd}
            required
            onChange={(date) => setTimelineEnd(date)}
          />
          <label htmlFor="communicationMethod">Communication Method:</label>
          <select
            id="communicationMethod"
            value={communicationMethod}
            required
            onChange={(e) => setCommunicationMethod(e.target.value)}
          >
            <option value="">Select Communication Method</option>
            <option value="Email">Email</option>
            <option value="Call Number">Call Number</option>
            <option value="WhatsApp">WhatsApp</option>
          </select>
          <label htmlFor="comments">Comments</label>
          <textarea
            id="comments"
            value={comments}
            required
            onChange={(e) => setComments(e.target.value)}
          ></textarea>
          <label htmlFor="packageType">Package Type</label>
          <select
            id="packageType"
            value={packageType}
            required
            onChange={(e) => setPackageType(e.target.value)}
          >
            <option value="">Select Package</option>
            <option value="Standard">Standard</option>
            <option value="Gold">Gold</option>
            <option value="Diamond">Diamond</option>
          </select>

          <div className="container__back-nextButton">
            <div className="back-btn">
              {windowIndex > 0 && (
                <button
                  type="button"
                  onClick={handleBack}
                  required
                  className="back-button"
                >
                 Back
                </button>
              )}
            </div>
            <div className="next-btn">
              <button type="submit">
                Next 
              </button>
            </div>
          </div>
        </form>
      )}

      {windowIndex === 3 && (
        <form onSubmit={handleFormSubmit}>
          {/* Fourth Window Fields */}

          <label htmlFor="height">Height</label>
          <input
            type="text"
            id="height"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />

          <label htmlFor="heightUnit">Unit:</label>
          <select
            id="heightUnit"
            value={heightUnit}
            onChange={(e) => setHeightUnit(e.target.value)}
            style={{ float: 'right' }}
          >
            <option value="inch">Inch</option>
            <option value="cm">Centimeter</option>
          </select>

          <label htmlFor="eyeColor">Eye Color:</label>
          <select
            id="eyeColor"
            value={eyeColor}
            onChange={(e) => setEyeColor(e.target.value)}
          >
            <option value="">Select Eye Color</option>
            <option value="Blue">Blue</option>
            <option value="Brown">Brown</option>
            <option value="Green">Green</option>
            <option value="Hazel">Hazel</option>
          </select>
          <label htmlFor="weight">Weight</label>
          <input
            type="text"
            id="weight"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />

          <Checkbox
            label="Agree to Privacy Policy"
            checked={agreedToPrivacyPolicy}
            onChange={handlePrivacyPolicyChange}
          />

          <div className="container__back-nextButton">
            <div className="back-btn">
              {windowIndex > 0 && (
                <button
                  type="button"
                  onClick={handleBack}
                  className="back-button"
                >
               Back
                </button>
              )}
            </div>

            <button type="submit" className="submit-btn">
              Submit
            </button>
            <div className="next-btn">
              <button
                type="button"
                onClick={handleFormCancel}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}
      </div>
        </>
  )
}

export default PersonalInformation



