import TopnavBar from '../../components/TopnavBar/TopnavBar'
import React, { useRef, useEffect, useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import navData from '../../data/navData'
import './Navbar.css'

function Navbar() {
  const navRef = useRef()
  const [isTop, setIsTop] = useState(true)
  const [activeLink, setActiveLink] = useState(null) //Track the active link
  const [activeSubmenu, setActiveSubmenu] = useState(null) // Track the active submenu

  const showNavbar = () => {
    navRef.current.classList.toggle('responsive_nav')
  }
  const handleLinkClick = (index) => {
    setActiveLink(index) // Set active link
    // Store active link in session storage
    sessionStorage.setItem('activeLink', index)
    // Clear active submenu when a new link is clicked
    setActiveSubmenu(null)

    // Close navbar after link is clicked (optional)
    showNavbar()
  }

  const handleSubmenuClick = (index) => {
    setActiveSubmenu(index) // Set active submenu

    // Store active submenu in session storage
    sessionStorage.setItem('activeSubmenu', index)
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const newIsTop = scrollTop === 0
      setIsTop(newIsTop)
    }

    window.addEventListener('scroll', handleScroll)

    // Get active link and submenu from session storage on component mount
    const storedActiveLink = sessionStorage.getItem('activeLink')
    if (storedActiveLink) {
      setActiveLink(parseInt(storedActiveLink))
    }

    const storedActiveSubmenu = sessionStorage.getItem('activeSubmenu')
    if (storedActiveSubmenu) {
      setActiveSubmenu(parseInt(storedActiveSubmenu))
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <TopnavBar />
      <header className={`navbar ${isTop ? 'top' : ''}`}>
        <h3 className="logo"></h3>
        <nav ref={navRef}>
          {navData.map((item, index) => (
            <React.Fragment key={index}>
              {item.submenu ? (
                <div className="has-submenu">
                  <a
                    href={item.link}
                    className={activeLink === index ? 'active' : ''}
                    onClick={() => handleLinkClick(index)}
                  >
                    {item.label}
                  </a>

                  <div className={`submenu ${activeLink === index ? 'active' : ''}`}>
                    {item.submenu.map((subItem, subIndex) => (
                      <a
                        key={subIndex}
                        href={subItem.link}
                        className={activeSubmenu === subIndex ? 'active' : ''}
                        onClick={() => handleSubmenuClick(subIndex)}
                      >
                        {subItem.label}
                      </a>
                    ))}
                  </div>
                </div>
              ) : (
                <a
                  href={item.link}
                  className={activeLink === index ? 'active' : ''}
                  onClick={() => handleLinkClick(index)}
                >
                  {item.label}
                </a>
              )}
            </React.Fragment>
          ))}
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </>
  )
}

export default Navbar
