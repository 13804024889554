  const slideData = [
    {
      heading: 'Discover the Truth',
      content:
        'Are you in need of answers? Our professional private investigation services are here to help you uncover the truth. Whether you suspect infidelity, need assistance with background checks, our experienced team of investigators is ready to assist you.',
    },
    {
      heading: 'Confidential & Reliable',
      content:
        'When it comes to private investigations, confidentiality and reliability are our top priorities. Our team of highly skilled and professional investigators is dedicated to protecting your privacy while providing you with accurate and timely results',
    },
  ]
  export default slideData