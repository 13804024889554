import React from 'react'
import Navbar from '../navbar/Navbar'
import ReusableComponent from '../../components/resusable/ReusableComponent'
import { infidelityInvestigation } from '../../data/pageData'
import Footer from '../../components/Footer/Footer'
import '../About/About.css'

const InfidelityInvestigatio = () => {
  return (
    <div>
      <Navbar/>
       <div className=' .about__aside-details'> 
        <div className="aboutUs__image-asideContent">
          <h1 className='about__us-title'>{infidelityInvestigation.title}</h1>
          <img src={infidelityInvestigation.image} alt="about__us-img" />
          <div className='content-component'>
            <div className="content-paragraphes">
            <p className='content1'>{infidelityInvestigation.contentInfidelity1}</p>
              <p className='content2'>{infidelityInvestigation.contentInfidelity2}</p>
            <p className='content3'>{infidelityInvestigation.contentInfidelity3}</p>
            <p className='content4'>{infidelityInvestigation.contentInfidelity4}</p>
              
            </div>

          <ReusableComponent />
          </div>
          
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default InfidelityInvestigatio