import React, { useState } from 'react'
import './Search.css'
import Navbar from '../pages/navbar/Navbar'
import { Link } from 'react-router-dom'

const Search = ({ handleSearch }) => {
  const [searchQuery, setSearchQuery] = useState('')

  const handleInputChange = (e) => {
    const query = e.target.value
    setSearchQuery(query)
    handleSearch(query)
  }

  return (
    <>
      <div className="search-container">
        <div className="search-item">
          <span className="search-icon">&#128270;</span>
          <input
            className="search-input"
            type="text"
            placeholder="Enter a name, investigation type, or ID to search..."
            value={searchQuery}
            onChange={handleInputChange}
          />
        </div>
        <Link className='go__back-home' to="/">Go Back Home</Link>

      </div>
    </>
  )
}

export default Search
