
import React, { useRef, useState, useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import emailjs from '@emailjs/browser';
import './Contact.css';
import Footer from '../../components/Footer/Footer';

const ContactPage = () => {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_4eqpnbl',
      'template_5z3whwb',
      form.current,
      'WQxcI9jsfnZlAf-6I'
    )
      .then((result) => {
        console.log(result.text);
        console.log("message sent");
        setEmailSent(true);
        form.current.reset(); // Reset the form values
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  useEffect(() => {
    if (emailSent) {
      const timeout = setTimeout(() => {
        setEmailSent(false); // Reset the emailSent state after a certain time
      }, 3000); // 3000 milliseconds = 3 seconds

      return () => clearTimeout(timeout);
    }
  }, [emailSent]);

  return (
    <div>
      <Navbar />
      <div className="contact-page">
        <h1>Contact Us</h1>
        <div className="contact-content">
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="user_name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="user_email" required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  required
                ></textarea>
              </div>
              <button type="submit" value="Send">Submit</button>
            </form>
   {emailSent && (
  <p style={{ animation: 'fadeOut 2s ease forwards' }} className="email-sent">
  The email has been sent successfully!.
  </p>
)}

 {/* Display the pop-up message */}
          </div>
          <div className="contact-info">
            <h2>Contact Information</h2>
            <p>123 Main Street</p>
            <p>Calgary, Canada</p>
            <p>Email: chatinvest@gmail.com</p>
            <p>Phone: +1 123 456 7890</p>
          </div>
        </div>
        <div className="map-container">
          <iframe
            title="Map"
            src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250103.11410002247!2d-114.0719!3d51.0447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537170039f843fd7%3A0x266d3bb410a1d67b!2sCalgary%2C%20AB%2C%20Canada!5e0!3m2!1sen!2sca!4v1623765200606!5m2!1sen!2sca`}
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactPage;

