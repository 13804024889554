const fetchNewsArticles = async () => {
  try {
    const response = await fetch(
      'https://newsapi.org/v2/everything?q=bitcoin&apiKey=6ddef64f8b9e488e9b902c4b0e297dc8',
    );
    const data = await response.json();
    const filteredArticles = data.articles.filter((article) => article.urlToImage);
    return filteredArticles;
  } catch (error) {
    console.log('Error fetching news articles:', error);
    return [];
  }
};

export default fetchNewsArticles;
