import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams, Link, useNavigate } from 'react-router-dom'
import './EditFormPage.css'

const EditFormPage = () => {
  const [formData, setFormData] = useState({})
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    // Fetch the form data for editing based on the ID
    axios
      .get(`https://chat-investigation1.onrender.com/api/Investigations/${id}`)
      .then((response) => {
        setFormData(response.data)
      })
      .catch((error) => {
        console.error('Error fetching form data:', error)
      })
  }, [id])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Send the updated form data to the server
    axios
      .put(`https://chat-investigation1.onrender.com/api/Investigations/${id}`, formData)
      .then((response) => {
        console.log('Form data updated successfully:', response)
        // Redirect to the admin page after successful update
        navigate('/admin')
      })
      .catch((error) => {
        console.error('Error updating form data:', error)
      })
  }

  const formatLastUpdatedDate = (dateString) => {
    const date = new Date(dateString)
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return date.toLocaleDateString(undefined, options)
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  return (
    <div className="edit-form-page">
      <h2 className="user__name-display">
        Edit Form Data for {formData.firstName}-{formData.lastName}
      </h2>
      <p className="updated-date__show">
        Last Updated: {formatLastUpdatedDate(formData.updatedAt)}
      </p>

      <form onSubmit={handleSubmit}>
        <div className="container-blogs">
          <div className="blog-left">
            <div className="form-field">
              <label>First Name:</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-field">
              <label>Last Name:</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Email:</label>
              <input
                type="text"
                name="email"
                value={formData.email || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Cellphone:</label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Address:</label>
              <input
                type="text"
                name="address"
                value={formData.address || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Investigation Type:</label>
              <input
                type="text"
                name="investigationType"
                value={formData.investigationType || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Description:</label>
              <input
                type="text"
                name="description"
                value={formData.description || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Time Start:</label>
              <input
                type="text"
                name="timelineStart"
                value={formatDate(formData.timelineStart || '')}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Time End:</label>
              <input
                type="text"
                name="timelineEnd"
                value={formatDate(formData.timelineEnd || '')}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="blog-right">
            <div>
              <label>Communication Method:</label>
              <input
                type="text"
                name="communicationMethod"
                value={formData.communicationMethod || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Comments:</label>
              <input
                type="text"
                name="comments"
                value={formData.comments || ''}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Package Type:</label>
              <input
                type="text"
                name="packageType"
                value={formData.comments || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Upload Doc:</label>
              <input
                type="text"
                name="uploadDocument"
                value={formData.uploadDocument || ''}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Height:</label>
              <input
                type="text"
                name="height"
                value={formData.height || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Height Unit:</label>
              <input
                type="text"
                name="heightUnit"
                value={formData.heightUnit || ''}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Eye Color:</label>
              <input
                type="text"
                name="eyeColor"
                value={formData.eyecolor || ''}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Weight:</label>
              <input
                type="text"
                name="weight"
                value={formData.weight || ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Date Submited:</label>
              <input
                type="text"
                name="createAt"
                value={formData.createAt || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-buttons">
              <button type="submit">Save</button>
              <Link to="/admin" className="cancel">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditFormPage
