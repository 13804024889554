import React, { useState } from 'react';
import { FaSearch, FaUser, FaShieldAlt, FaVideo, FaCog, FaUserCircle } from 'react-icons/fa';
import { IoMdKey } from 'react-icons/io';
import cardData from '../../data/cardData';
import './ReusableComponent.css';
import { Link } from 'react-router-dom';

const ReusableComponent = () => {
  const phoneNumber = '+243 453 363 522';
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleActiveColor = (index) => {
    setActiveIndex(index);
    handleClick()
  };

  return (
    <div>
      <div className="container-about">
        <div className="about-content">
          <div className="about-details"></div>

          <div className="Container-asideDetails__asideService">
            <div className="about__aside-details">
              <h2>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. At
                impedit totam corporis ducimus perspiciatis
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptates, natus a eveniet corporis eos neque in ipsum
                similique inventore vitae?
              </p>
              <button className='call-button'>
                <Link to={`tel:${phoneNumber}`}> Call {phoneNumber} </Link>
              </button>
            </div>

            <div className="about__aside-services">
              <h1>Services</h1>
              {cardData.map((item, index) => (
                <div
                  key={index}
                  className={`icon-title ${index === activeIndex ? 'active' : ''}`}
                  onClick={() => handleActiveColor(index)}
                >
                  {/*test handeActive test */}
                  <div className={`about__icon-service ${index === activeIndex ? 'active' : ''}`}
                  onClick={() => handleActiveColor(index)} key={index}
                  >
                    {item.symbol}
                  </div>
                  {/*test handeActive test */}
                  <Link to={item.link}>
                    <h3 className="about__title-service">{item.title}</h3>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReusableComponent;
