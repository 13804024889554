import React, { useEffect, useState } from 'react'
import Navbar from '../navbar/Navbar'
import './News.css'
import Footer from '../../components/Footer/Footer'
import ReusableComponent from '../../components/resusable/ReusableComponent'
import fetchNewsArticles from './NewsAPI'

const News = () => {
  const [newsArticles, setNewsArticles] = useState([])
  const [visibleArticles, setVisibleArticles] = useState([])
  const [displayCount, setDisplayCount] = useState(4)

  useEffect(() => {
    fetchArticles()
  }, [])

  useEffect(() => {
    setVisibleArticles(newsArticles.slice(0, displayCount))
  }, [newsArticles, displayCount])

  const fetchArticles = async () => {
    const articles = await fetchNewsArticles()
    setNewsArticles(articles)
  }

  const handleSeeMore = () => {
    setDisplayCount((prevCount) => prevCount + 4)
  }

  const handleSeeLess = () => {
    setDisplayCount(4)
  }

  return (
    <div>
      <Navbar />
      <div className="container__reusableComponent-news">
        <div className="news-page">
          <h1>Latest News</h1>
          <div className="news-container">
            {newsArticles.length > 0 ? (
              visibleArticles.map((article, index) => (
                <div key={index} className="news-article">
                  <div className="date-time">
                    <p className="news-date">
                      Date:{' '}
                      {new Date(article.publishedAt).toISOString().slice(0, 10)}
                    </p>
                    <p className="news-time">
                      {new Date(article.publishedAt).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </p>
                  </div>
                  <h3>{article.title}</h3>
                  <img src={article.urlToImage} alt={article.title} />
                  <p>{article.content}</p>
                  <a
                    href={article.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read More
                  </a>
                </div>
              ))
            ) : (
              <p>Loading news articles...</p>
            )}
          </div>
          {newsArticles.length > 4 && displayCount < newsArticles.length ? (
            <button className="see-more-button" onClick={handleSeeMore}>
              See More...
            </button>
          ) : (
            <button className="see-less-button" onClick={handleSeeLess}>
              See Less
            </button>
          )}
        </div>
        <ReusableComponent />
      </div>
      <Footer />
    </div>
  )
}

export default News
