import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Search from './Search'
import './AdminPage.css'

const FormDataTable = ({ formData, setCurrentIndex, handleDelete }) => {
  const [searchQuery, setSearchQuery] = useState('')

  const handleUserClick = (index) => {
    setCurrentIndex(index)
  }

  const handleSearch = (query) => {
    setSearchQuery(query)
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }
  const sortedFormData = formData.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  )
  const filteredFormData = sortedFormData.filter((data) => {
    const query = searchQuery.toLowerCase()
    const nameMatch =
      data.firstName.toLowerCase().includes(query) ||
      data.lastName.toLowerCase().includes(query)
    const investigationTypeMatch = data.investigationType
      .toLowerCase()
      .includes(query)
    const idMatch = data._id.toLowerCase().includes(query)

    return nameMatch || investigationTypeMatch || idMatch
  })

  return (
    <div className="form-data-container">
      {/* Importing Search component */}
      <Search handleSearch={handleSearch} />

      {filteredFormData.length === 0 ? (
        <div className="no-results">
          <h3>No results found...</h3>
          <p>Please refine your search criteria and try again.</p>
        </div>
      ) : (
        filteredFormData.map((data, index) => (
          <div
            key={data.id}
            className={`form-data user-${index} ${formatDate(data.createdAt)}`}
          >
            <h3>
              {`${data.firstName} ${data.lastName}: ${formatDate(
                data.createdAt,
              )}`}
            </h3>
            <div className="table-container">
              <table className="form-data-table">
                {/* Table header */}
                <thead className="thead">
                  <tr>
                    {/* Table header columns here */}
                    <th>Id</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Investigation Type</th>
                    <th>Description</th>
                    <th>Timeline Start</th>
                    <th>Timeline End</th>
                    <th>Communication Method</th>
                    <th>Comments</th>
                    <th>Package Type</th>
                    <th>Document Uploaded</th>
                    <th>Height</th>
                    <th>Height Unit</th>
                    <th>Eye Color</th>
                    <th>Weight</th>
                    <th>Date Created</th>
                    <th>Date Updated</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={data.id}>
                    {/* Add table body rows here */}
                    <td data-label="Id">{data._id.slice(-4)}</td>
                    <td
                      data-label="First Name"
                      onClick={() => handleUserClick(index)}
                    >
                      {data.firstName}
                    </td>
                    <td data-label="Last Name">{data.lastName}</td>
                    <td data-label="Email">{data.email}</td>
                    <td data-label="Phone">{data.phoneNumber}</td>
                    <td data-label="Address">{data.address}</td>
                    <td data-label="Investigation Type">
                      {data.investigationType}
                    </td>
                    <td data-label="Description">{data.description}</td>
                    <td data-label="Timeline Start">
                      {formatDate(data.timelineStart)}
                    </td>
                    <td data-label="Timeline End">
                      {formatDate(data.timelineEnd)}
                    </td>
                    <td data-label="Communication Method">
                      {data.communicationMethod}
                    </td>
                    <td data-label="Comments">{data.comments}</td>
                    <td data-label="Package Type">{data.packageType}</td>
                    <td data-label="Document Uploaded">
                      {data.uploadDocument}
                    </td>
                    <td data-label="Height">{data.height}</td>
                    <td data-label="Height Unit">{data.heightUnit}</td>
                    <td data-label="Eye Color">{data.eyeColor}</td>
                    <td data-label="Weight">{data.weight}</td>
                    <td data-label="Date Created">
                      {formatDate(data.createdAt)}
                    </td>
                    <td data-label="Date Updated">
                      {formatDate(data.updatedAt)}
                    </td>
                    <td data-label="Actions">
                      <div className="actions-container">
                        <button
                          onClick={() => handleDelete(data._id)}
                          className="delete"
                        >
                          Delete
                        </button>
                        <Link
                          to={{
                            pathname: `/edit/${data._id}`,
                            state: { formData: data },
                          }}
                          className="edit"
                        >
                          Edit
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr className="user-separator" />
          </div>
        ))
      )}
    </div>
  )
}

export default FormDataTable
