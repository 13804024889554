import React from 'react'
import { Link } from 'react-router-dom';
import cardData from '../../data/cardData';

import './AsideAbout.css'

const AsideAbout = () => {
  const phoneNumber = '+243 812 464 345'
  return (
    <div>
      <div className="container-about">
        <div className="about-content">
          <div className="about-details">
            <h1>About</h1>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Aspernaturconsectetur adipisicing elit.Aspernatur rerum totam
              voluptates rem voluptate harum inventore id saepe itaque
              molestias.lorem Lorem ipsum dolor, sit amet consectetur
              adipisicing elit. Aspernatur rerum totam voluptates rem voluptate
              harum inventore id saepe itaque molestias.loremLorem ipsum dolor,
              sit ame consectetur adipisicing
            </p>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ullam,
              laborum praesentium ex eos alias molestias dolores rem quibusdam
              saepe officia.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia
              dolorum provident itaque. Esse impedit voluptates iste
              reprehenderit debitis ut culpa?
            </p>
          </div>
          <div className="about__aside-details">
            <h2>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. At
              impedit totam corporis ducimus perspiciatis
            </h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptates, natus a eveniet corporis eos neque in ipsum similique
              inventore vitae?
            </p>
            {/* <button>Call(+243) 456-7890</button> */}
             <button className='call-button'>
                <Link to={`tel:${phoneNumber}`}> Call {phoneNumber} </Link>
              </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AsideAbout
