import React, { useState, useRef } from 'react';
import Navbar from '../navbar/Navbar';
import ReusableComponent from '../../components/resusable/ReusableComponent';
import { aboutUsData } from '../../data/pageData';
import Footer from '../../components/Footer/Footer';
// import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import './About.css';

const About = () => {
  const [showFullText, setShowFullText] = useState(false);

  const handleReadMoreClick = () => {
    setShowFullText(true);
  };
  const handleReadLessClick = () => {
    setShowFullText(false);
    window.scrollTo({ top: 500, behavior: 'smooth' });
  };

  return (
    <div>
      <Navbar />
      <div className=".about__aside-details">
        <div className="aboutUs__image-asideContent">
          <h1 className="about__us-title">{aboutUsData.title}</h1>
          <img src={aboutUsData.image} alt="about__us-img" />
          <div className="content-component">
            <div className="content-paragraphes">
              <p className="content1">{aboutUsData.contentAbout1}</p>
              <p className="content2">{aboutUsData.contentAbout2}</p>
              {showFullText ? (
                <React.Fragment>
                  <p className="content3">{aboutUsData.contentAbout3}</p>
                  <p className="content4">{aboutUsData.contentAbout4}</p>
                </React.Fragment>
              ) : (
                <p className="content3">{aboutUsData.contentAbout3.substring(0, 100)}</p>
              )}
              {aboutUsData.contentAbout3.length > 100 && !showFullText && (
                <button onClick={handleReadMoreClick} className="read-more-button">
                  Read More
                </button>
              )}
              {showFullText && (
                <button onClick={handleReadLessClick} className="read-more-button">
                  Read Less
                </button>
              )}
            </div>
            <ReusableComponent />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;






