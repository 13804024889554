import React from 'react'
import { Link } from 'react-router-dom';
import './TopnavBar.css'

const TopnavBar = () => {
  return (
    <div>
      <div className="container__top-nav">
        <div className="logo">
          <Link to='/'>
            <img src="https://cdn-icons-png.flaticon.com/512/3430/3430085.png" alt='logo' />
          </Link>
        </div>
        <div className="top__navbar-contatct">
          <p> (+1) 403 3645 383</p>
          <p>Email: habari@gmail.com </p>
        </div>
      </div>
    </div>
  )
}

export default TopnavBar
