import React, { useState, useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import testimonialsData from '../../data/testimonialsData';
import ReusableComponent from '../../components/resusable/ReusableComponent';
import './Testimonial.css'
import Footer from '../../components/Footer/Footer';

const Testimonial = ({ name, image, content, rate, isActive }) => (
  <div className={`testimonial ${isActive ? 'active' : ''}`}>
    <img src={image} alt={name} className="testimonial-image" />
    <div className="testimonial-content">
      <h3 className="testimonial-name">{name}</h3>
      <p>{content}</p>
      <div className="testimonial-rate">Rating: {rate} out of 5</div>
    </div>
  </div>
);

const TestimonialSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === testimonialsData.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? testimonialsData.length - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Slide testimonials every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div>

    <div className="testimonial-slider">
      <button className="slider-button prev-button" onClick={prevSlide}>
        Prev
      </button>
      <div className="testimonial-container">
        {testimonialsData.map((testimonial, index) => (
          <Testimonial
            key={index}
            name={testimonial.name}
            image={testimonial.image}
            content={testimonial.content}
            rate={testimonial.rate}
            isActive={index === currentSlide}
          />
        ))}
      </div>
      <button className="slider-button next-button" onClick={nextSlide}>
        Next
      </button>
      </div>

    </div>
    
  );
};

const TestimonialPage = () => (
  <div>
    <Navbar />
    <div className='container__testimonial-reusableComponent'>
  
    <div className='testimonial-content'>
       <h1>Testimonials</h1>
    <TestimonialSlider />
      </div>
          <div className='testimonial-reusable'>
      <ReusableComponent />
        
    </div>
    </div>
    <Footer/>
  </div>
);

export default TestimonialPage;
