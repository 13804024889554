import React from 'react'
import Navbar from '../navbar/Navbar'
import faqData from '../../data/faqData';
import ReusableComponent from '../../components/resusable/ReusableComponent';
import Footer from '../../components/Footer/Footer'
import './Faq.css'

const Faq = () => {

  return (
    <div>
      <Navbar />
      <div className="container__faq-aside">
      <div className='container-faq'>
      <h1>Frequently Asked Questions</h1>
      {faqData.map((faq, index) => (
        <div key={index}>
          <h3>{faq.question}</h3>
          <p>{faq.answer}</p>
        </div>
      ))}
        </div>
          <ReusableComponent/>
      </div>
      <Footer/>
    </div>
  );
};

export default Faq;
