const testimonialsData = [
  {
    name: 'John Doe',
    image: '/images/img1.jpg',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    rate: 1,
  },
  {
    name: 'Jane Smith',
    image: '/images/img3.jpg',
    content: 'Praesent cursus mauris non massa lobortis consequat.',
    rate: 2,
  },
  {
    name: 'Mike Johnson',
    image: '/images/img8.jpg',
    content: 'Fusce dignissim metus id ex egestas luctus.',
    rate: 3,
  },
  {
    name: 'Emily Davis',
    image: '/images/img7.jpg',
    content: 'Vivamus ac nunc blandit, malesuada odio a, pellentesque nisl.',
    rate: 4,
  },
  {
    name: 'Denis Muk',
    image: '/images/img10.jpg',
    content: 'Vivamus ac nunc blandit, malesuada odio a, pellentesque nisl.',
    rate: 5,
  },
];
export default testimonialsData