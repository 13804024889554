import React from 'react'
import Navbar from '../navbar/Navbar'
import ReusableComponent from '../../components/resusable/ReusableComponent'
import { corporateInvestigation } from '../../data/pageData'
import Footer from '../../components/Footer/Footer'
import '../About/About.css'

const CorporateInvestigation = () => {
  return (
    <div>
      <Navbar/>
       <div className=' .about__aside-details'> 
        <div className="aboutUs__image-asideContent">
          <h1 className='about__us-title'>{corporateInvestigation.title}</h1>
          <img src={corporateInvestigation.image} alt="about__us-img" />
          <div className='content-component'>
            <div className="content-paragraphes">
            <p className='content1'>{corporateInvestigation.contentCorporate1}</p>
              <p className='content2'>{corporateInvestigation.contentCorporate2}</p>
            <p className='content3'>{corporateInvestigation.contentCorporate3}</p>
            <p className='content4'>{corporateInvestigation.contentCorporate4}</p>
              
            </div>

          <ReusableComponent />
          </div>
          
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default CorporateInvestigation