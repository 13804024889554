
const faqData = [
  {
question: 'What types of cases do you handle?',
answer:
'Our private investigation services cover a wide range of cases, including infidelity investigations, background checks, corporate investigations, missing persons, and more. We have the expertise and resources to handle various situations and provide you with the information you need.',
  },

{
question: 'How do you ensure confidentiality?',
answer:
'Confidentiality is of utmost importance to us. We have strict protocols in place to safeguard your privacy and ensure that all information and investigations remain confidential. Our team of investigators operates discreetly and professionally, respecting your confidentiality at all times.',
},
{
question: 'How long does an investigation typically take?',
answer:
'The duration of an investigation varies depending on the nature and complexity of the case. Some cases may be resolved quickly, while others require more time for thorough investigation. During your initial consultation, we can provide you with an estimated timeline based on the details of your specific case.',
},
{
question: 'How much does a private investigation cost?',
answer:
"The cost of a private investigation depends on several factors, such as the type of case, the scope of the investigation, and the resources required. We offer customized solutions tailored to your specific needs and budget. Contact us for a free quote and we'll provide you with a transparent breakdown of the costs involved.",
},
{
question: 'Are your investigators licensed and experienced?',
answer:
'Yes, all our investigators are licensed professionals with extensive experience in the field of private investigation. They undergo rigorous training and stay updated with the latest investigative techniques and legal requirements. Rest assured, your case will be handled by competent and skilled investigators.',
},
{
question: 'How do I get started with your services?',
answer:
'Getting started is easy. Simply contact us to schedule an initial consultation where we can discuss your case in detail. We will assess your needs, provide recommendations, and offer a free quote for our services. From there, we can begin the investigation process and work towards resolving your concerns.',
},
{
question: 'Can I trust the information gathered during the investigation?',
answer:
'We strive to gather accurate and reliable information during our investigations. Our team employs professional methods, utilizes advanced technology, and follows strict protocols to ensure the integrity of the evidence collected. You can trust that the information we provide is based on thorough and meticulous investigation.',
},
];

export default faqData;