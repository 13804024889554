import React, { useState, useEffect } from 'react'
import Navbar from '../navbar/Navbar'
import cardData from '../../data/cardData'
import slideData from '../../data/slideData'
import './Home.css'
import AsideAbout from '../../components/AsideAbout/AsideAbout'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import fetchNewsArticles from '../News/NewsAPI'

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [newsArticles, setNewsArticles] = useState([])

  const phoneNumber = '+243 453 363 522'
  const [activeIndex, setActiveIndex] = useState(null)

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slideData.length - 1 : prevSlide - 1,
    )
  }

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slideData.length - 1 ? 0 : prevSlide + 1,
    )
  }

  useEffect(() => {
    const timer = setInterval(goToNextSlide, 3000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleActiveColor = (index) => {
    setActiveIndex(index)
    handleClick()
  }

  useEffect(() => {
    fetchNews()
  }, [])

  const fetchNews = async () => {
    try {
      const articles = await fetchNewsArticles()
      setNewsArticles(articles.slice(0,2))
    } catch (error) {
      console.error('Error fetching news:', error)
    }
  }

  return (
    <>
      <Navbar />
      <div className="container-home">
        <div className="main-container">
          <div className="main-content">
            <div className="main-image">
              <img src="/images/img17.jpg" alt="image" />
            </div>

            <div className="get__free-quote">
              <Link to='/FreeQuote' className="quote-button"> Get Free Quote Now</Link>
              <div className="container-slider">
                <div className={`slide slide-${currentSlide + 1}`}>
                  <div className="main-text-slider">
                    <h2>{slideData[currentSlide].heading}</h2>
                    <p>{slideData[currentSlide].content}</p>
                  </div>
                  <div className="slider-controls">
                    <a className="prev-btn" onClick={goToPreviousSlide}>
                      Prev
                    </a>
                    <a className="next-btn" onClick={goToNextSlide}>
                      Next
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="container__service-card">
            {cardData.map((card, index) => (
              <div className="cards" key={index} onClick={() => handleClick()}>
                <div className="card__title-symbol">
                  <div className="card-symbol">{card.symbol}</div>
                  <h2 className="card-title">{card.title}</h2>
                </div>
                <p className="card-detail">{card.paragraph}</p>
                <Link to={card.link} className="card-button">
                  {card.buttonText}
                </Link>
              </div>
            ))}
          </div>
        </div>
        <AsideAbout />
        {/**Aside Services */}
        <h1 className='home__news-testimonial'>News & Testimonial</h1>
        <div className="container__aside-service">
          <div className="services">
            <div className="testimonials">
              <div className="testimonial-card">
                <div className="testimonial-content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Pariatur eveniet, quod repudiandae unde, ad a est maiores ab
                    dolorem tenetur deserunt perspiciatis quos sed
                    reprehenderit!
                  </p>
                  <div className="testimonial__name-rate">
                    <p className="testimonial-name">John Doe</p>
                    <p className="testimonial-rating">4.5</p>
                  </div>
                </div>
              </div>

              <div className="testimonial-card">
                <div className="testimonial-content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Pariatur eveniet, quod repudiandae unde, ad a est maiores ab
                    dolorem tenetur deserunt perspiciatis quos sed
                    reprehenderit!
                  </p>
                  <div className="testimonial__name-rate">
                    <p className="testimonial-name">Jane Smith</p>
                    <p className="testimonial-rating">5.0</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="content__aside-services">
              <h1>Services</h1>
              {cardData.map((card, index) => (
                <Link
                  to={card.link}
                  className="services"
                  key={index}
                  onClick={() => handleActiveColor()}
                >
                  <div className="service__title-symbol">
                    <div className="service-symbol">{card.symbol}</div>
                    <h2 className="service-title">{card.title}</h2>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="container__home-news">
          <div className="second__container-news">
            {newsArticles
              .slice(0)
              .reverse()
              .map((article, index) => (
                <div className="news" key={index}>
                  <div className="head-news">
                    <h3 className="title-news">{article.title}</h3>
                    <p className="date-news">
                      {new Date(article.publishedAt).toLocaleDateString()}
                    </p>
                  </div>

                  <div className="content__image-news">
                    <img src={article.urlToImage} alt="image news" />
                    <p className="content-news">{article.description}</p>
                  </div>
                  <a href={article.url} className="read__more-news">
                    Read More
                  </a>
                </div>
              ))}
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Home
