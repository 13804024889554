
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './AdminPage.css'
import FormDataTable from './FormDataTable'

const AdminPage = () => {
  const [formData, setFormData] = useState([])
  const [currentIndex, setCurrentIndex] = useState(null)

  useEffect(() => {
    // Fetch form data from the backend server
    axios
      .get('https://chat-investigation1.onrender.com/api/Investigations')
      .then((response) => {
        setFormData(response.data)
      })
      .catch((error) => {
        console.error('Error fetching form data:', error)
      })
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement
      const scrollPercentage = scrollTop / (scrollHeight - clientHeight) || 0
      const currentIndex = Math.floor(scrollPercentage * formData.length)
      setCurrentIndex(currentIndex)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [formData.length])

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this form data?',
    )
    if (confirmDelete) {
      console.log('Deleting form data with id:', id)
      axios
        .delete(`https://chat-investigation1.onrender.com/api/Investigations/${id}`)
        .then((response) => {
          setFormData(formData.filter((data) => data._id !== id))
        })
        .catch((error) => {
          console.error('Error deleting form data:', error)
        })
    }
  }

  const handleUserClick = (index) => {
    setCurrentIndex(index)
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  return (
    <>
      <div className="admin-container">
        <div className="admin-page">
          <h2>Form Data</h2>
          {currentIndex !== null && (
            <div className="user-count">{`Users: ${currentIndex + 1}/${
              formData.length
            }`}</div>
          )}
          <div className="scroll-container">
            <FormDataTable
              formData={formData}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              handleDelete={handleDelete}
              formatDate={formatDate}
              handleUserClick={handleUserClick}
            />
          </div>
        </div>
      </div>
      
    </>
  )
}

export default AdminPage

