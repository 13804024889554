import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Login.css'
import Navbar from '../pages/navbar/Navbar'
import Footer from './Footer/Footer'

const Login = ({ setLoggedIn }) => {
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')

  const navigate = useNavigate()

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleLogin = () => {
    // Check if the entered password is correct
    const adminName = 'admin' // Admin LoginName
    const adminPassword = '1234' // Admin Password

    if (password === adminPassword && name === adminName) {
      setLoggedIn(true)
      navigate('/admin')
    } else if (password === '' || name === '') {
      alert('Name and password required.')
    } else {
      alert('Invalid Admin Name or Password. Please try again.')
    }
  }

  return (
    <>
      <Navbar />
      <div className="login-container">
        <div className="login-form">
          <h2>Login</h2>
          <input
            type="name"
            placeholder="Enter Name"
            value={name}
            onChange={handleNameChange}
          />
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={handlePasswordChange}
          />
          <button onClick={handleLogin}>Login</button>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Login
