export const aboutUsData = {
  image: '/images/img2.jpg',
  title: '/About-Us',
  contentAbout1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur, repellat quibusdam quae.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur, repellat quibusdam quae.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur,',

  contentAbout2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur, repellat quibusdam quae.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur, repellat quibusdam quae.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur,',
    
  contentAbout3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur, repellat quibusdam quae.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur, repellat quibusdam quae.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur,Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur, repellat quibusdam quae.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur, repellat quibusdam quae.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur,Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur, repellat quibusdam quae.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur, repellat quibusdam quae.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur,',
    
  contentAbout4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur, repellat quibusdam quae.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur, repellat quibusdam quae.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore excepturi corrupti qui ut assumenda quisquam enim pariatur,',
    
}

export const privateInvestigation = {
  image: '/images/img6.jpg',
  title: '/Private-Investigation',
  contentPrivate1: 'Welcome to our private investigation services! At [Your Company Name], we specialize in providing comprehensive and professional investigative solutions for individuals and organizations. With our expertise, discretion, and commitment to delivering accurate results, we strive to meet your investigative needs effectively.',

 contentPrivate2: 'Our team of highly trained private investigators is skilled in various investigative techniques, including surveillance, background checks, asset searches, and more. Whether you require assistance in a legal matter, need to locate a missing person, or suspect fraudulent activity, we have the knowledge and resources to help.',
    
  contentPrivate3: 'We understand the sensitive nature of our work, and we handle each case with utmost confidentiality and professionalism. Rest assured that your privacy and trust are our top priorities. We adhere to legal and ethical standards, ensuring that all investigations are conducted lawfully and within the bounds of confidentiality.',
    
contentPrivate4: 'Contact us today to discuss your specific needs and learn more about how our private investigation services can assist you. We are dedicated to providing accurate and timely information to help you make informed decisions and find the answers you seek. Trust [Your Company Name] for reliable and discreet investigative services'
}

export const domesticInvestigation = {
  image: '/images/img7.jpg',
  title: '/Domestic-Invevstigation',
  contentDomestic1: 'At Chat-Invest, we specialize in domestic investigations, addressing sensitive matters within households and families. Our compassionate and experienced team of private investigators understands the challenges you may be facing and is here to support you through difficult times.',

contentDomestic2: 'Whether you suspect infidelity, need assistance in a child custody dispute, or require help in resolving domestic disputes, our investigators will approach your case with empathy, discretion, and professionalism. We utilize proven investigative techniques, including surveillance, background checks, and discreet interviews, to gather the evidence and information you need',
    
  contentDomestic3: 'We prioritize the confidentiality and well-being of our clients, ensuring that your privacy is protected throughout the investigation. Our goal is to provide you with the truth, enabling you to make informed decisions and find resolution in your personal matters.',
    
  contentDomestic4: 'Chat-Invest today to discuss your domestic investigation needs. Our dedicated team is here to listen, understand, and provide you with the support and answers you seek. Trust us to handle your case with the care and expertise it deserves',
    
}

export const insuranceInvestigation = {
  image: '/images/img8.jpg',
  title: '/Insurance-Investigation',
  contentInsurance1: 'When it comes to insurance claims, accuracy and integrity are paramount. At Chat-Invest, our skilled team of private investigators specializes in insurance investigations, helping insurance companies uncover fraudulent claims and protect their interests',

  contentInsurance2: 'We conduct thorough and objective investigations to verify the legitimacy of insurance claims. Our investigators are trained to identify red flags, inconsistencies, and patterns that may indicate fraudulent activities. Through detailed analysis, interviews, and evidence collection, we strive to provide insurance companies with the information they need to make informed decisions.',
    
  contentInsurance3: 'We understand the importance of a timely and cost-effective investigation process. Our team is equipped with the latest investigative tools and techniques, allowing us to deliver accurate results efficiently. You can rely on us to handle each case with professionalism, confidentiality, and adherence to legal guidelines.',
    
  contentInsurance4: 'If you require assistance with insurance investigations, contact [Your Company Name] today. Our dedicated team is ready to work closely with you, ensuring a comprehensive investigation that protects your interests and preserves the integrity of your insurance claims.',
    
}

export const bugSweeps = {
  image: '/images/img13.jpg',
  title: '/Bug-Sweeps',
  contentBug1:"Protecting your privacy and ensuring confidentiality is crucial in today's world. At [Your Company Name], we specialize in bug sweeps, also known as Technical Surveillance Countermeasures (TSCM), to detect and remove unauthorized surveillance devices.",

  contentBug2: 'Our team of highly trained investigators uses state-of-the-art equipment and techniques to sweep for hidden audio or video recording devices, GPS trackers, and other monitoring equipment. We conduct thorough sweeps of corporate offices, boardrooms, high-profile residences, and other environments where privacy is essential.',
    
  contentBug3: 'We understand the importance of maintaining your privacy and safeguarding sensitive information. Our bug sweep services are conducted discreetly, with strict adherence to professional standards and confidentiality. Rest assured that your privacy is our top priority.',
    
  contentBug4: 'If you suspect that your privacy may have been compromised, contact [Your Company Name] for professional bug sweep services. Our experienced team will provide a comprehensive assessment, remove any detected surveillance devices, and give you peace of mind in your private spaces.',
    
}

export const corporateInvestigation = {
  image: '/images/img16.jpg',
  title: '/Corporate-Investigation',
  contentCorporate1: 'In the corporate world, safeguarding your business and protecting your interests are essential. At Chat-Invest, we offer comprehensive corporate investigation services to help businesses mitigate risks, uncover misconduct, and ensure compliance',

  contentCorporate2: 'Our team of experienced private investigators specializes in a wide range of corporate investigations, including employee misconduct, theft, fraud, due diligence for mergers and acquisitions, intellectual property infringement, and internal policy violations. We work closely with businesses to understand their unique needs and tailor our investigative approach accordingly.',
    
  contentCorporate3: 'We conduct thorough investigations, utilizing advanced techniques, industry databases, and interviews to gather evidence and provide actionable insights. Our investigators maintain the highest level of professionalism, ensuring confidentiality, integrity, and adherence to legal and ethical standards throughout the investigation process.',
    
  contentCorporate4: 'When you partner with [Your Company Name], you can trust that we will prioritize the protection of your business and provide you with accurate information to make informed decisions. Contact us today to discuss your corporate investigation needs and how we can help you safeguard your business.'
}

export const locatePeople = {
  image: '/images/img5.jpg',
  title: '/Locate-People',
  contentLocate1: 'Are you trying to find a missing person or reconnect with a long-lost loved one? At [Your Company Name], our dedicated team of private investigators specializes in locate people investigations, utilizing advanced techniques and resources to track down individuals.',

  contentLocate2: 'We understand that locating a missing person can be a stressful and emotional experience. Our compassionate investigators are committed to providing you with the support and expertise needed to find the person you are searching for.',
    
  contentLocate3: "Using a combination of public records, database searches, interviews, and other investigative methods, we strive to locate individuals efficiently and effectively. Whether it's a missing family member, an old friend, or a witness to a legal matter, we will do our utmost to reunite you with your loved one or find the person you need.",
    
  contentLocate4: 'If you require assistance in locating a person, contact [Your Company Name] today. We are dedicated to helping you find closure, reconnect with loved ones, or obtain the information you need. Trust us to handle your locate people investigation with care, professionalism, and determination.',
    
}

export const infidelityInvestigation = {
  image: '/images/img1.jpg',
  title: '/Infidelity-Investigation',
  contentInfidelity1: 'Suspecting infidelity in a relationship can be emotionally challenging. At Chat-Invest, we specialize in infidelity investigations, providing discreet and professional services to uncover the truth.',

  contentInfidelity2: 'Our compassionate team of private investigators understands the delicate nature of infidelity cases. We approach each investigation with empathy and confidentiality, ensuring that your emotional well-being and privacy are respected throughout the process.',
    
  contentInfidelity3: 'Using a combination of surveillance, background checks, and other investigative techniques, we gather evidence to confirm or dispel suspicions of infidelity. Our investigators are trained to handle sensitive situations with care, providing you with the truth you seek while maintaining your dignity.',
    
  contentInfidelity4: 'When you choose [Your Company Name] for your infidelity investigation, you can trust that we will handle your case with the utmost professionalism and discretion. Contact us today to discuss your concerns and take the first step toward uncovering the truth in your relationship.'
}
