import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer" id="footer-component">
      <div className="container-footer">
        <div className="row">
          <div className="col-md-4">
            <h3>About Us</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis sollicitudin eros ac sagittis.</p>
          </div>
          <div className="col-md-4">
            <h3>Contact Us</h3>
            <p>123 Main Street, City, State, ZIP</p>
            <p>Email: info@example.com</p>
            <p>Phone: (123) 456-7890</p>
          </div>
          <div className="col-md-4">
            <h3>Follow Us</h3>
            <div className="social-icons">
              <a href="#"><i className="fab fa-facebook"></i></a>
              <a href="#"><i className="fab fa-twitter"></i></a>
              <a href="#"><i className="fab fa-instagram"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-subfooter">
        <div className="subfooter">
        Copyright © 2023 InvestigationChat.
      </div>
      </div>
      
    </footer>
  );
};

export default Footer;
