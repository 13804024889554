
const investigationTypes = [
  {emoji: "🔍", id: 1, name: "Background Checks" },
  {emoji: "💔", id: 2, name: "Infidelity Investigations"},
  {emoji: "💰", id: 3, name: "Fraud & Financial Crimes"},
  {emoji: "🏗️" , id: 4, name: "Construction Progress Verification"},
  {emoji: "🙅‍♂️" , id: 5, name: "Employee Misconduct"},
  {emoji: "🎓" , id: 6, name: "Education Verification"},
  { emoji: "👥" ,id: 7, name: "Missing Persons"},
  {emoji: "💻" , id: 8, name: "Cybercrime & Digital Investigations"},
  {emoji: "🕵️", id: 9, name: "Surveillance & Security Services" },
  {emoji: "💼", id: 10, name: "Debt Collection & Asset Recovery"},
  {emoji: "📝", id: 11, name: "Insurance Claims Verification"},
  {emoji: "🌐", id: 12, name: "Online Reputation Management" },
  {emoji: "💸", id: 13, name: "Financial Transactions Monitoring"},
  {emoji: "⚖️" , id: 14, name: "Intellectual Property Protection"},
];

export default investigationTypes;
