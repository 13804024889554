import React from 'react'
import Navbar from '../navbar/Navbar'
import ReusableComponent from '../../components/resusable/ReusableComponent'
import { privateInvestigation } from '../../data/pageData'
import Footer from '../../components/Footer/Footer'
import '../About/About.css'

const PrivateInvestigation = () => {
  return (
    <div>
      <Navbar/>
       <div className=' .about__aside-details'> 
        <div className="aboutUs__image-asideContent">
          <h1 className='about__us-title'>{privateInvestigation.title}</h1>
          <img src={privateInvestigation.image} alt="about__us-img" />
          <div className='content-component'>
            <div className="content-paragraphes">
            <p className='content1'>{privateInvestigation.contentPrivate1}</p>
              <p className='content2'>{privateInvestigation.contentPrivate2}</p>
            <p className='content3'>{privateInvestigation.contentPrivate3}</p>
           
              
            </div>

          <ReusableComponent />
          </div>
          
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default PrivateInvestigation