import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PersonalInformation from './components/PersonalInformation';
import Login from './components/Login';
import AdminPage from './components/AdminPage';
import EditPage from './components/EditFormPage'
// import Navbar from './pages/navbar/Navbar';
import Home from './pages/HomePage/Home';
import About from './pages/About/About'
import PrivateInvestigation from './pages/Services/PrivateInvestigation'
import DomesticInvestigation from './pages/Services/DomesticInvestigation'

import Testimonial from './pages/Testimonial/Testimonial'
import Faq from './pages/Faq/Faq'
import News from './pages/News/News'
import Contact from './pages/Contact/Contact'
import InsuranceInvestigation from './pages/Services/InsuranceInvestigation';
import BugSweeps from './pages/Services/BugSweeps';
import CorporateInvestigation from './pages/Services/CorporateInvestigation';
import LocatePeople from './pages/Services/LocatePeople';
import InfidelityInvestigation from './pages/Services/InfidelityInvestigation';


const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services/private-investigation" element={<PrivateInvestigation />}/>
        <Route path="/services/domestic-investigation" element={<DomesticInvestigation />} />
        <Route path="/services/insurance-investigation" element={<InsuranceInvestigation/>} />
        <Route path="/services/bug-sweeps" element={<BugSweeps />} />
        <Route path="/services/corporate-investigation" element={<CorporateInvestigation />} />
        <Route path="/services/locate-people" element={<LocatePeople />} />
        <Route path="/services/infidelity-investigation" element={<InfidelityInvestigation/>} />
      
        <Route path="/testimonial" element={<Testimonial/>} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/news" element={<News/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/freequote" element={<PersonalInformation />} />
        <Route
          path="/login"
          element={<Login setLoggedIn={setLoggedIn} />}
        />
        <Route
          path="/admin"
          element={
            loggedIn ? <AdminPage /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/edit/:id"
          element={<EditPage/>}
        />

      </Routes>
    </Router>
  );
};

export default App;

