import React from 'react'
import Navbar from '../navbar/Navbar'
import ReusableComponent from '../../components/resusable/ReusableComponent'
import { bugSweeps } from '../../data/pageData'
import Footer from '../../components/Footer/Footer'
import '../About/About.css'

const BugSweeps = () => {
  return (
    <div>
      <Navbar/>
       <div className=' .about__aside-details'> 
        <div className="aboutUs__image-asideContent">
          <h1 className='about__us-title'>{bugSweeps.title}</h1>
          <img src={bugSweeps.image} alt="about__us-img" />
          <div className='content-component'>
            <div className="content-paragraphes">
            <p className='content1'>{bugSweeps.contentBug1}</p>
              <p className='content2'>{bugSweeps.contentBug2}</p>
            <p className='content3'>{bugSweeps.contentBug3}</p>
            <p className='content4'>{bugSweeps.contentBug4}</p>
              
            </div>

          <ReusableComponent />
          </div>
          
        </div>
      </div>
<Footer/>
    </div>
  )
}

export default BugSweeps